import Sidebar, {ISidebarItem} from '.';
import {ICONS} from '../../constants';

const items: ISidebarItem[] = [
  {
    icon: ICONS.profiler,
    label: 'Profiler',
    link: '/',
    pattern: '/projects/[project-id]',
  },
  {
    icon: ICONS.settings,
    label: 'Settings',
    link: '/project-settings',
    pattern: '/project-settings',
  },
  {
    icon: ICONS.help,
    label: 'Setup',
    link: '/setup',
    pattern: '/setup',
  },
  {
    icon: ICONS.documentation,
    label: 'Documentation',
    link: 'https://polarsignals.com/docs',
    pattern: 'https://polarsignals.com/docs',
    anchorProps: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
];

export const ProjectSidebar = () => {
  return <Sidebar items={items} />;
};
