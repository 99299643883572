import {Icon} from '@iconify/react';
import {toast} from 'react-hot-toast';

const Success = ({message}: {message: string}) => {
  const handleClick = () => {
    toast.dismiss();
  };

  return (
    <div
      id="toast-success"
      className="mb-4 flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
      role="alert"
    >
      <div className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
        <Icon icon="ion:checkmark" width={20} height={20} />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <button
        type="button"
        className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
        data-dismiss-target="#toast-success"
        aria-label="Close"
        onClick={() => {
          handleClick();
        }}
      >
        <span className="sr-only">Close</span>
        <Icon icon="ic:baseline-close" width={20} height={20} />
      </button>
    </div>
  );
};

const Error = ({message}: {message: string}) => {
  const handleClick = () => {
    toast.dismiss();
  };

  return (
    <div
      id="toast-warning"
      className="flex w-full max-w-xs items-center rounded-lg bg-white p-4 text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400"
      role="alert"
    >
      <div className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
        <Icon icon="clarity:warning-solid" width={20} height={20} />
      </div>
      <div className="ml-3 text-sm font-normal">{message}</div>
      <button
        type="button"
        className="-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white"
        data-dismiss-target="#toast-warning"
        aria-label="Close"
        onClick={() => {
          handleClick();
        }}
      >
        <span className="sr-only">Close</span>
        <Icon icon="ic:baseline-close" width={20} height={20} />
      </button>
    </div>
  );
};

const ToastSuccess = (message: string) => {
  toast.custom(<Success message={message} />);
};

const ToastError = (message: string) => {
  toast.custom(<Error message={message} />);
};

const Toast = (type: 'success' | 'error', message: string) => {
  if (type === 'success') {
    return ToastSuccess(message);
  }
  if (type === 'error') {
    return ToastError(message);
  }
};

export default Toast;
