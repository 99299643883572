// @generated by protobuf-ts 2.8.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "polarsignals/organization/v1alpha1/organization.proto" (package "polarsignals.organization.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { OrganizationService } from "./organization";
import type { GetInviteTokensResponse } from "./organization";
import type { GetInviteTokensRequest } from "./organization";
import type { AcceptInviteTokenResponse } from "./organization";
import type { AcceptInviteTokenRequest } from "./organization";
import type { IsInviteTokenValidResponse } from "./organization";
import type { IsInviteTokenValidRequest } from "./organization";
import type { RemoveOrganizationUserResponse } from "./organization";
import type { RemoveOrganizationUserRequest } from "./organization";
import type { AddOrganizationUserResponse } from "./organization";
import type { AddOrganizationUserRequest } from "./organization";
import type { GetOrganizationUsersResponse } from "./organization";
import type { GetOrganizationUsersRequest } from "./organization";
import type { DeleteOrganizationResponse } from "./organization";
import type { DeleteOrganizationRequest } from "./organization";
import type { UpdateOrganizationResponse } from "./organization";
import type { UpdateOrganizationRequest } from "./organization";
import type { GetOrganizationResponse } from "./organization";
import type { GetOrganizationRequest } from "./organization";
import type { CreateOrganizationResponse } from "./organization";
import type { CreateOrganizationRequest } from "./organization";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetOrganizationsResponse } from "./organization";
import type { GetOrganizationsRequest } from "./organization";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * OrganizationService provides the ability to manage organizations.
 *
 * @generated from protobuf service polarsignals.organization.v1alpha1.OrganizationService
 */
export interface IOrganizationServiceClient {
    /**
     * GetOrganization returns the organization with the given ID.
     *
     * @generated from protobuf rpc: GetOrganizations(polarsignals.organization.v1alpha1.GetOrganizationsRequest) returns (polarsignals.organization.v1alpha1.GetOrganizationsResponse);
     */
    getOrganizations(input: GetOrganizationsRequest, options?: RpcOptions): UnaryCall<GetOrganizationsRequest, GetOrganizationsResponse>;
    /**
     * CreateOrganization creates a new organization.
     *
     * @generated from protobuf rpc: CreateOrganization(polarsignals.organization.v1alpha1.CreateOrganizationRequest) returns (polarsignals.organization.v1alpha1.CreateOrganizationResponse);
     */
    createOrganization(input: CreateOrganizationRequest, options?: RpcOptions): UnaryCall<CreateOrganizationRequest, CreateOrganizationResponse>;
    /**
     * GetOrganization returns the organization with the given ID.
     *
     * @generated from protobuf rpc: GetOrganization(polarsignals.organization.v1alpha1.GetOrganizationRequest) returns (polarsignals.organization.v1alpha1.GetOrganizationResponse);
     */
    getOrganization(input: GetOrganizationRequest, options?: RpcOptions): UnaryCall<GetOrganizationRequest, GetOrganizationResponse>;
    /**
     * UpdateOrganization updates the organization with the given ID.
     *
     * @generated from protobuf rpc: UpdateOrganization(polarsignals.organization.v1alpha1.UpdateOrganizationRequest) returns (polarsignals.organization.v1alpha1.UpdateOrganizationResponse);
     */
    updateOrganization(input: UpdateOrganizationRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationRequest, UpdateOrganizationResponse>;
    /**
     * DeleteOrganization deletes the organization with the given ID.
     *
     * @generated from protobuf rpc: DeleteOrganization(polarsignals.organization.v1alpha1.DeleteOrganizationRequest) returns (polarsignals.organization.v1alpha1.DeleteOrganizationResponse);
     */
    deleteOrganization(input: DeleteOrganizationRequest, options?: RpcOptions): UnaryCall<DeleteOrganizationRequest, DeleteOrganizationResponse>;
    /**
     * GetOrganizationUsers returns the users of the organization with the given ID.
     *
     * @generated from protobuf rpc: GetOrganizationUsers(polarsignals.organization.v1alpha1.GetOrganizationUsersRequest) returns (polarsignals.organization.v1alpha1.GetOrganizationUsersResponse);
     */
    getOrganizationUsers(input: GetOrganizationUsersRequest, options?: RpcOptions): UnaryCall<GetOrganizationUsersRequest, GetOrganizationUsersResponse>;
    /**
     * AddORganizationUser adds a user to the organization with the given ID.
     *
     * @generated from protobuf rpc: AddOrganizationUser(polarsignals.organization.v1alpha1.AddOrganizationUserRequest) returns (polarsignals.organization.v1alpha1.AddOrganizationUserResponse);
     */
    addOrganizationUser(input: AddOrganizationUserRequest, options?: RpcOptions): UnaryCall<AddOrganizationUserRequest, AddOrganizationUserResponse>;
    /**
     * RemoveOrganizationUser removes a user from the organization with the given ID.
     *
     * @generated from protobuf rpc: RemoveOrganizationUser(polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest) returns (polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse);
     */
    removeOrganizationUser(input: RemoveOrganizationUserRequest, options?: RpcOptions): UnaryCall<RemoveOrganizationUserRequest, RemoveOrganizationUserResponse>;
    /**
     * IsInviteTokenValid checks if the given invite token is valid.
     *
     * @generated from protobuf rpc: IsInviteTokenValid(polarsignals.organization.v1alpha1.IsInviteTokenValidRequest) returns (polarsignals.organization.v1alpha1.IsInviteTokenValidResponse);
     */
    isInviteTokenValid(input: IsInviteTokenValidRequest, options?: RpcOptions): UnaryCall<IsInviteTokenValidRequest, IsInviteTokenValidResponse>;
    /**
     * AcceptInviteToken accepts the given invite token.
     *
     * @generated from protobuf rpc: AcceptInviteToken(polarsignals.organization.v1alpha1.AcceptInviteTokenRequest) returns (polarsignals.organization.v1alpha1.AcceptInviteTokenResponse);
     */
    acceptInviteToken(input: AcceptInviteTokenRequest, options?: RpcOptions): UnaryCall<AcceptInviteTokenRequest, AcceptInviteTokenResponse>;
    /**
     * GetInviteToken returns the invite token with the given ID.
     *
     * @generated from protobuf rpc: GetInviteTokens(polarsignals.organization.v1alpha1.GetInviteTokensRequest) returns (polarsignals.organization.v1alpha1.GetInviteTokensResponse);
     */
    getInviteTokens(input: GetInviteTokensRequest, options?: RpcOptions): UnaryCall<GetInviteTokensRequest, GetInviteTokensResponse>;
}
/**
 * OrganizationService provides the ability to manage organizations.
 *
 * @generated from protobuf service polarsignals.organization.v1alpha1.OrganizationService
 */
export class OrganizationServiceClient implements IOrganizationServiceClient, ServiceInfo {
    typeName = OrganizationService.typeName;
    methods = OrganizationService.methods;
    options = OrganizationService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetOrganization returns the organization with the given ID.
     *
     * @generated from protobuf rpc: GetOrganizations(polarsignals.organization.v1alpha1.GetOrganizationsRequest) returns (polarsignals.organization.v1alpha1.GetOrganizationsResponse);
     */
    getOrganizations(input: GetOrganizationsRequest, options?: RpcOptions): UnaryCall<GetOrganizationsRequest, GetOrganizationsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrganizationsRequest, GetOrganizationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateOrganization creates a new organization.
     *
     * @generated from protobuf rpc: CreateOrganization(polarsignals.organization.v1alpha1.CreateOrganizationRequest) returns (polarsignals.organization.v1alpha1.CreateOrganizationResponse);
     */
    createOrganization(input: CreateOrganizationRequest, options?: RpcOptions): UnaryCall<CreateOrganizationRequest, CreateOrganizationResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateOrganizationRequest, CreateOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetOrganization returns the organization with the given ID.
     *
     * @generated from protobuf rpc: GetOrganization(polarsignals.organization.v1alpha1.GetOrganizationRequest) returns (polarsignals.organization.v1alpha1.GetOrganizationResponse);
     */
    getOrganization(input: GetOrganizationRequest, options?: RpcOptions): UnaryCall<GetOrganizationRequest, GetOrganizationResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrganizationRequest, GetOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateOrganization updates the organization with the given ID.
     *
     * @generated from protobuf rpc: UpdateOrganization(polarsignals.organization.v1alpha1.UpdateOrganizationRequest) returns (polarsignals.organization.v1alpha1.UpdateOrganizationResponse);
     */
    updateOrganization(input: UpdateOrganizationRequest, options?: RpcOptions): UnaryCall<UpdateOrganizationRequest, UpdateOrganizationResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateOrganizationRequest, UpdateOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteOrganization deletes the organization with the given ID.
     *
     * @generated from protobuf rpc: DeleteOrganization(polarsignals.organization.v1alpha1.DeleteOrganizationRequest) returns (polarsignals.organization.v1alpha1.DeleteOrganizationResponse);
     */
    deleteOrganization(input: DeleteOrganizationRequest, options?: RpcOptions): UnaryCall<DeleteOrganizationRequest, DeleteOrganizationResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteOrganizationRequest, DeleteOrganizationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetOrganizationUsers returns the users of the organization with the given ID.
     *
     * @generated from protobuf rpc: GetOrganizationUsers(polarsignals.organization.v1alpha1.GetOrganizationUsersRequest) returns (polarsignals.organization.v1alpha1.GetOrganizationUsersResponse);
     */
    getOrganizationUsers(input: GetOrganizationUsersRequest, options?: RpcOptions): UnaryCall<GetOrganizationUsersRequest, GetOrganizationUsersResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetOrganizationUsersRequest, GetOrganizationUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * AddORganizationUser adds a user to the organization with the given ID.
     *
     * @generated from protobuf rpc: AddOrganizationUser(polarsignals.organization.v1alpha1.AddOrganizationUserRequest) returns (polarsignals.organization.v1alpha1.AddOrganizationUserResponse);
     */
    addOrganizationUser(input: AddOrganizationUserRequest, options?: RpcOptions): UnaryCall<AddOrganizationUserRequest, AddOrganizationUserResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddOrganizationUserRequest, AddOrganizationUserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RemoveOrganizationUser removes a user from the organization with the given ID.
     *
     * @generated from protobuf rpc: RemoveOrganizationUser(polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest) returns (polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse);
     */
    removeOrganizationUser(input: RemoveOrganizationUserRequest, options?: RpcOptions): UnaryCall<RemoveOrganizationUserRequest, RemoveOrganizationUserResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveOrganizationUserRequest, RemoveOrganizationUserResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * IsInviteTokenValid checks if the given invite token is valid.
     *
     * @generated from protobuf rpc: IsInviteTokenValid(polarsignals.organization.v1alpha1.IsInviteTokenValidRequest) returns (polarsignals.organization.v1alpha1.IsInviteTokenValidResponse);
     */
    isInviteTokenValid(input: IsInviteTokenValidRequest, options?: RpcOptions): UnaryCall<IsInviteTokenValidRequest, IsInviteTokenValidResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<IsInviteTokenValidRequest, IsInviteTokenValidResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * AcceptInviteToken accepts the given invite token.
     *
     * @generated from protobuf rpc: AcceptInviteToken(polarsignals.organization.v1alpha1.AcceptInviteTokenRequest) returns (polarsignals.organization.v1alpha1.AcceptInviteTokenResponse);
     */
    acceptInviteToken(input: AcceptInviteTokenRequest, options?: RpcOptions): UnaryCall<AcceptInviteTokenRequest, AcceptInviteTokenResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<AcceptInviteTokenRequest, AcceptInviteTokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * GetInviteToken returns the invite token with the given ID.
     *
     * @generated from protobuf rpc: GetInviteTokens(polarsignals.organization.v1alpha1.GetInviteTokensRequest) returns (polarsignals.organization.v1alpha1.GetInviteTokensResponse);
     */
    getInviteTokens(input: GetInviteTokensRequest, options?: RpcOptions): UnaryCall<GetInviteTokensRequest, GetInviteTokensResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInviteTokensRequest, GetInviteTokensResponse>("unary", this._transport, method, opt, input);
    }
}
