/** @jsxImportSource theme-ui */
import {Input as ParcaInput} from '@parca/components';

interface Props {
  label?: string;
  helpText?: string;
}

const Input = ({label, helpText, ...props}: Props & React.HTMLProps<HTMLInputElement>) => {
  return (
    <div>
      {label ? <p className="mt-3 mb-2 text-sm text-gray-500 dark:text-gray-400">{label}</p> : null}
      <div>
        <ParcaInput
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          {...props}
        />
        {helpText ? (
          <p className="mt-3 mb-2 text-sm" sx={{color: 'textSofter'}}>
            {helpText}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
