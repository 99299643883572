import {MoonIcon, SunIcon} from '@heroicons/react/solid';
import {useColorMode} from 'theme-ui';

const ColorModeToggle = () => {
  const [colorMode, setColorMode] = useColorMode();
  const Icon = colorMode === '__default' ? MoonIcon : SunIcon;
  return (
    <button
      type="button"
      className="align-center flex cursor-pointer items-center rounded-full p-1"
    >
      <Icon
        onClick={() => {
          setColorMode(colorMode === '__default' ? 'dark' : '__default');
        }}
        className="h-5 w-5"
        aria-hidden="true"
      />
    </button>
  );
};

export default ColorModeToggle;
