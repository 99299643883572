// @generated by protobuf-ts 2.8.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "polarsignals/user/v1alpha1/user.proto" (package "polarsignals.user.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * User represents a user.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.User
 */
export interface User {
    /**
     * The unique identifier of the user.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the user.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The email address of the user.
     *
     * @generated from protobuf field: string email = 3;
     */
    email: string;
    /**
     * The company of the user.
     *
     * @generated from protobuf field: string company = 4;
     */
    company: string;
    /**
     * The time when the user was created.
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * The time when the user was last updated.
     *
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 6;
     */
    updatedAt?: Timestamp;
    /**
     * the feature flags enabled for the user.
     *
     * @generated from protobuf field: repeated string feature_flags = 7;
     */
    featureFlags: string[];
}
/**
 * GetSelfRequest is the request for the GetSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.GetSelfRequest
 */
export interface GetSelfRequest {
}
/**
 * GetSelfResponse is the response for the GetSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.GetSelfResponse
 */
export interface GetSelfResponse {
    /**
     * The user information.
     *
     * @generated from protobuf field: polarsignals.user.v1alpha1.User user = 1;
     */
    user?: User;
}
/**
 * UpdateSelfRequest is the request for the UpdateSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UpdateSelfRequest
 */
export interface UpdateSelfRequest {
    /**
     * The user information to update.
     *
     * @generated from protobuf field: polarsignals.user.v1alpha1.User user = 1;
     */
    user?: User;
}
/**
 * UpdateSelfResponse is the response for the UpdateSelf method.
 *
 * @generated from protobuf message polarsignals.user.v1alpha1.UpdateSelfResponse
 */
export interface UpdateSelfResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("polarsignals.user.v1alpha1.User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "company", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "feature_flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { id: "", name: "", email: "", company: "", featureFlags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string company */ 4:
                    message.company = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 6:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* repeated string feature_flags */ 7:
                    message.featureFlags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string company = 4; */
        if (message.company !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.company);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 6; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string feature_flags = 7; */
        for (let i = 0; i < message.featureFlags.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.featureFlags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSelfRequest$Type extends MessageType<GetSelfRequest> {
    constructor() {
        super("polarsignals.user.v1alpha1.GetSelfRequest", []);
    }
    create(value?: PartialMessage<GetSelfRequest>): GetSelfRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSelfRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSelfRequest): GetSelfRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetSelfRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.GetSelfRequest
 */
export const GetSelfRequest = new GetSelfRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSelfResponse$Type extends MessageType<GetSelfResponse> {
    constructor() {
        super("polarsignals.user.v1alpha1.GetSelfResponse", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetSelfResponse>): GetSelfResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetSelfResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSelfResponse): GetSelfResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.user.v1alpha1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSelfResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.user.v1alpha1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.GetSelfResponse
 */
export const GetSelfResponse = new GetSelfResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfRequest$Type extends MessageType<UpdateSelfRequest> {
    constructor() {
        super("polarsignals.user.v1alpha1.UpdateSelfRequest", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<UpdateSelfRequest>): UpdateSelfRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSelfRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSelfRequest): UpdateSelfRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.user.v1alpha1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateSelfRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.user.v1alpha1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UpdateSelfRequest
 */
export const UpdateSelfRequest = new UpdateSelfRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSelfResponse$Type extends MessageType<UpdateSelfResponse> {
    constructor() {
        super("polarsignals.user.v1alpha1.UpdateSelfResponse", []);
    }
    create(value?: PartialMessage<UpdateSelfResponse>): UpdateSelfResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateSelfResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateSelfResponse): UpdateSelfResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateSelfResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.user.v1alpha1.UpdateSelfResponse
 */
export const UpdateSelfResponse = new UpdateSelfResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.user.v1alpha1.UserService
 */
export const UserService = new ServiceType("polarsignals.user.v1alpha1.UserService", [
    { name: "GetSelf", options: { "google.api.http": { get: "/v1/self" } }, I: GetSelfRequest, O: GetSelfResponse },
    { name: "UpdateSelf", options: { "google.api.http": { patch: "/v1/self", body: "*" } }, I: UpdateSelfRequest, O: UpdateSelfResponse }
]);
