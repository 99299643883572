import Sidebar, {ISidebarItem} from '.';
import {ICONS} from '../../constants';

const items: ISidebarItem[] = [
  {
    icon: ICONS.userFlags,
    label: 'User Flags',
    link: '/admin/user-flags',
    pattern: '/admin/user-flags',
  },
  {
    icon: ICONS.orgFlags,
    label: 'Organization Flags',
    link: '/admin/org-flags',
    pattern: '/admin/org-flags',
  },
];

export const AdminSidebar = () => {
  return <Sidebar items={items} />;
};
