import {useState} from 'react';

import {Button, Input} from '@parca/components';

import Modal from 'components/Modal';

const AddOrganizationModal = ({
  addOrganization,
  isModalOpen,
  closeModal,
}: {
  addOrganization: (name: string) => Promise<string | undefined>;
  isModalOpen: boolean;
  closeModal: () => void;
}) => {
  const [newOrganizationName, setNewOrganizationName] = useState<string>('');

  const addNewOrganization = (name: string) => {
    addOrganization(name);
    setNewOrganizationName('');
    closeModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      closeModal={() => closeModal()}
      title="Add a New Organization"
      className="w-full"
    >
      <p className="mt-3 mb-2 text-sm text-gray-500 dark:text-gray-400">
        Enter your new organization name to continue.
      </p>

      <div>
        <Input
          type="text"
          value={newOrganizationName}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setNewOrganizationName(e.currentTarget.value);
          }}
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        />
      </div>

      <div className="mt-4">
        <Button
          className="w-fit"
          onClick={() => addNewOrganization(newOrganizationName)}
          disabled={newOrganizationName === ''}
        >
          Create Organization
        </Button>
      </div>
    </Modal>
  );
};

export default AddOrganizationModal;
