import {useEffect, useContext, useCallback, useMemo} from 'react';

import {
  Organization,
  Token,
  Project,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {Toaster as Toast} from '@polarsignals/components/';
import {useGrpcQuery} from '@polarsignals/hooks';
import router from 'next/router';

import GrpcContext from 'contexts/GrpcContext';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {globalProjects, setActiveOrgId, setActiveProjectId} from 'store/slices/projectsSlice';
import {hasNoProjects} from 'utils/projects';

import useProjects from '../useProjects';

interface additionalData {
  tokens?: Token[];
  organizationId?: string;
}

const findProject = (organizations: Organization[], projectId: string) => {
  for (const organization of organizations) {
    for (const project of organization.projects) {
      if (project.id === projectId) {
        return {...project, organizationId: organization.id};
      }
    }
  }
  return null;
};

type IProjectData = Project & additionalData;

const useActiveProject = (withTokens: boolean = false) => {
  const {projectServiceClient} = useContext(GrpcContext);
  const {activeProjectId} = useAppSelector(globalProjects);
  const dispatch = useAppDispatch();

  const projects = useProjects();

  const {
    data: tokens,
    isLoading,
    error,
    refetch,
  } = useGrpcQuery<Token[]>({
    key: ['getProjectTokens', activeProjectId],
    queryFn: async () => {
      if (!activeProjectId) {
        return [];
      }
      const {response} = await projectServiceClient.getProjectTokens({id: activeProjectId ?? ''});
      return response?.tokens || [];
    },
    options: {enabled: withTokens && !!activeProjectId},
  });

  const activeProject: IProjectData | null = useMemo(() => {
    if (!activeProjectId) {
      return null;
    }
    const {
      data: {organizations},
    } = projects;

    const activeProject = findProject(organizations ?? [], activeProjectId) ?? {
      name: '',
      id: '',
      organizationId: '',
    };
    if (!withTokens) {
      return activeProject;
    }
    return {...activeProject, tokens};
  }, [activeProjectId, projects, tokens, withTokens]);

  useEffect(() => {
    refetch();
  }, [activeProjectId, refetch]);

  const setActiveProject = useCallback(
    async (orgId: string, projectId: string) => {
      dispatch(setActiveOrgId(orgId));
      dispatch(setActiveProjectId(projectId));
    },
    [dispatch]
  );

  const deleteToken = useCallback(
    (token: Token) => {
      if (!activeProjectId) {
        return;
      }

      const call = projectServiceClient.deleteProjectToken({
        id: activeProjectId,
        tokenId: token.id,
      });

      call.response
        .then(() => {
          Toast('success', 'Token deleted successfully');
          refetch();
        })
        .catch(err => {
          Toast('error', `Error deleting token, please try again: ${err.message}`);
        });
    },
    [activeProjectId, refetch, projectServiceClient]
  );

  const createToken = (name: string) => {
    if (!activeProjectId) {
      return;
    }

    return new Promise((resolve, reject) => {
      const call = projectServiceClient.createProjectToken({
        id: activeProjectId,
        name: name,
      });

      call.response
        .then(res => {
          Toast('success', 'Token created successfully');
          refetch();
          resolve(res.token);
        })
        .catch(err => {
          Toast('error', `Error creating token, please try again: ${err.message}`);
          reject(err);
        });
    });
  };

  const deleteProject = useCallback(() => {
    if (!activeProjectId) {
      return;
    }

    const call = projectServiceClient.deleteProject({id: activeProjectId});
    call.response
      .then(() => {
        Toast('success', 'Project deleted successfully');
        refetch();
        projects.refetch();
        router.push('/organizations');
      })
      .catch(err => {
        Toast('error', `Error deleting project, please try again: ${err.message}`);
      });
  }, [activeProjectId, projectServiceClient, projects, refetch]);

  const updateProjectName = useCallback(
    name => {
      if (!activeProjectId) {
        return;
      }
      const call = projectServiceClient.updateProject({
        id: activeProjectId,
        name: name,
      });

      call.response
        .then(() => {
          Toast('success', 'Project details updated successfully');
          refetch();
          projects.refetch();
        })
        .catch(err => {
          Toast('error', `Error updating project details, please try again: ${err.message}`);
        });
    },
    [activeProjectId, refetch, projects, projectServiceClient]
  );

  return {
    data: {activeProject},
    loading:
      isLoading ||
      projects.loading ||
      (!activeProject && !hasNoProjects(projects.data?.organizations)),
    error,
    mutations: {setActiveProject, deleteToken, deleteProject, updateProjectName, createToken},
  };
};

export default useActiveProject;
