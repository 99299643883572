import {Popover as PopoverComp} from '@headlessui/react';
import {Icon} from '@iconify/react';
import {usePopper} from 'react-popper';
import {LegacyRef, useState} from 'react';

interface Props {
  trigger: React.ReactNode;
  content: React.ReactNode;
}

export const Popover = ({trigger, content}: Props) => {
  const [referenceElement, setReferenceElement] = useState<Element | undefined>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | undefined>();
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          tether: false,
          altAxis: true,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  return (
    <div className="">
      <PopoverComp className="relative">
        {({open}) => (
          <>
            <PopoverComp.Button
              ref={setReferenceElement as LegacyRef<HTMLButtonElement>}
              className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md px-3 py-2 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {trigger} <Icon icon="ic:round-keyboard-arrow-down" />
            </PopoverComp.Button>
            <PopoverComp.Panel
              className="z-10 mt-2 w-screen max-w-[540px] px-4 sm:px-0"
              ref={setPopperElement as LegacyRef<HTMLDivElement>}
              style={styles.popper}
              {...attributes.popper}
            >
              {content}
            </PopoverComp.Panel>
          </>
        )}
      </PopoverComp>
    </div>
  );
};
