// @generated by protobuf-ts 2.8.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "polarsignals/featureflag/v1alpha1/featureflag.proto" (package "polarsignals.featureflag.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { FeatureFlagService } from "./featureflag";
import type { RemoveOrganizationFlagResponse } from "./featureflag";
import type { RemoveOrganizationFlagRequest } from "./featureflag";
import type { AddOrganizationFlagResponse } from "./featureflag";
import type { AddOrganizationFlagRequest } from "./featureflag";
import type { ListOrganizationsResponse } from "./featureflag";
import type { ListOrganizationsRequest } from "./featureflag";
import type { ListOrganizationFlagsResponse } from "./featureflag";
import type { ListOrganizationFlagsRequest } from "./featureflag";
import type { DeleteFlagResponse } from "./featureflag";
import type { DeleteFlagRequest } from "./featureflag";
import type { AddFlagResponse } from "./featureflag";
import type { AddFlagRequest } from "./featureflag";
import type { ListFlagsResponse } from "./featureflag";
import type { ListFlagsRequest } from "./featureflag";
import type { FlagEnabledResponse } from "./featureflag";
import type { FlagEnabledRequest } from "./featureflag";
import type { RemoveUserFlagResponse } from "./featureflag";
import type { RemoveUserFlagRequest } from "./featureflag";
import type { AddUserFlagResponse } from "./featureflag";
import type { AddUserFlagRequest } from "./featureflag";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListUsersResponse } from "./featureflag";
import type { ListUsersRequest } from "./featureflag";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * FeatureFlagService provides the ability to manage feature flags.
 *
 * @generated from protobuf service polarsignals.featureflag.v1alpha1.FeatureFlagService
 */
export interface IFeatureFlagServiceClient {
    /**
     * ListFeatureFlags returns a list of feature flags.
     *
     * @generated from protobuf rpc: ListUsers(polarsignals.featureflag.v1alpha1.ListUsersRequest) returns (polarsignals.featureflag.v1alpha1.ListUsersResponse);
     */
    listUsers(input: ListUsersRequest, options?: RpcOptions): UnaryCall<ListUsersRequest, ListUsersResponse>;
    /**
     * AddUserFlag adds a new feature flag to a user.
     *
     * @generated from protobuf rpc: AddUserFlag(polarsignals.featureflag.v1alpha1.AddUserFlagRequest) returns (polarsignals.featureflag.v1alpha1.AddUserFlagResponse);
     */
    addUserFlag(input: AddUserFlagRequest, options?: RpcOptions): UnaryCall<AddUserFlagRequest, AddUserFlagResponse>;
    /**
     * RemoveUserFlag removes a feature flag from a user.
     *
     * @generated from protobuf rpc: RemoveUserFlag(polarsignals.featureflag.v1alpha1.RemoveUserFlagRequest) returns (polarsignals.featureflag.v1alpha1.RemoveUserFlagResponse);
     */
    removeUserFlag(input: RemoveUserFlagRequest, options?: RpcOptions): UnaryCall<RemoveUserFlagRequest, RemoveUserFlagResponse>;
    /**
     * FlagEnabled returns true if the feature flag is enabled for the user.
     *
     * @generated from protobuf rpc: FlagEnabled(polarsignals.featureflag.v1alpha1.FlagEnabledRequest) returns (polarsignals.featureflag.v1alpha1.FlagEnabledResponse);
     */
    flagEnabled(input: FlagEnabledRequest, options?: RpcOptions): UnaryCall<FlagEnabledRequest, FlagEnabledResponse>;
    /**
     * ListFlags returns a list of feature flags.
     *
     * @generated from protobuf rpc: ListFlags(polarsignals.featureflag.v1alpha1.ListFlagsRequest) returns (polarsignals.featureflag.v1alpha1.ListFlagsResponse);
     */
    listFlags(input: ListFlagsRequest, options?: RpcOptions): UnaryCall<ListFlagsRequest, ListFlagsResponse>;
    /**
     * AddFlag adds a new feature flag.
     *
     * @generated from protobuf rpc: AddFlag(polarsignals.featureflag.v1alpha1.AddFlagRequest) returns (polarsignals.featureflag.v1alpha1.AddFlagResponse);
     */
    addFlag(input: AddFlagRequest, options?: RpcOptions): UnaryCall<AddFlagRequest, AddFlagResponse>;
    /**
     * DeleteFlag deletes a feature flag.
     *
     * @generated from protobuf rpc: DeleteFlag(polarsignals.featureflag.v1alpha1.DeleteFlagRequest) returns (polarsignals.featureflag.v1alpha1.DeleteFlagResponse);
     */
    deleteFlag(input: DeleteFlagRequest, options?: RpcOptions): UnaryCall<DeleteFlagRequest, DeleteFlagResponse>;
    /**
     * ListOrganizationFlags returns a list of available feature flags for organizations.
     *
     * @generated from protobuf rpc: ListOrganizationFlags(polarsignals.featureflag.v1alpha1.ListOrganizationFlagsRequest) returns (polarsignals.featureflag.v1alpha1.ListOrganizationFlagsResponse);
     */
    listOrganizationFlags(input: ListOrganizationFlagsRequest, options?: RpcOptions): UnaryCall<ListOrganizationFlagsRequest, ListOrganizationFlagsResponse>;
    /**
     * ListOrganizations returns a list of organizations with feature flags.
     *
     * @generated from protobuf rpc: ListOrganizations(polarsignals.featureflag.v1alpha1.ListOrganizationsRequest) returns (polarsignals.featureflag.v1alpha1.ListOrganizationsResponse);
     */
    listOrganizations(input: ListOrganizationsRequest, options?: RpcOptions): UnaryCall<ListOrganizationsRequest, ListOrganizationsResponse>;
    /**
     * AddOrganizationFlag adds a new feature flag to an organization.
     *
     * @generated from protobuf rpc: AddOrganizationFlag(polarsignals.featureflag.v1alpha1.AddOrganizationFlagRequest) returns (polarsignals.featureflag.v1alpha1.AddOrganizationFlagResponse);
     */
    addOrganizationFlag(input: AddOrganizationFlagRequest, options?: RpcOptions): UnaryCall<AddOrganizationFlagRequest, AddOrganizationFlagResponse>;
    /**
     * RemoveOrganizationFlag removes a feature flag from an organization.
     *
     * @generated from protobuf rpc: RemoveOrganizationFlag(polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagRequest) returns (polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagResponse);
     */
    removeOrganizationFlag(input: RemoveOrganizationFlagRequest, options?: RpcOptions): UnaryCall<RemoveOrganizationFlagRequest, RemoveOrganizationFlagResponse>;
}
/**
 * FeatureFlagService provides the ability to manage feature flags.
 *
 * @generated from protobuf service polarsignals.featureflag.v1alpha1.FeatureFlagService
 */
export class FeatureFlagServiceClient implements IFeatureFlagServiceClient, ServiceInfo {
    typeName = FeatureFlagService.typeName;
    methods = FeatureFlagService.methods;
    options = FeatureFlagService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * ListFeatureFlags returns a list of feature flags.
     *
     * @generated from protobuf rpc: ListUsers(polarsignals.featureflag.v1alpha1.ListUsersRequest) returns (polarsignals.featureflag.v1alpha1.ListUsersResponse);
     */
    listUsers(input: ListUsersRequest, options?: RpcOptions): UnaryCall<ListUsersRequest, ListUsersResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUsersRequest, ListUsersResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * AddUserFlag adds a new feature flag to a user.
     *
     * @generated from protobuf rpc: AddUserFlag(polarsignals.featureflag.v1alpha1.AddUserFlagRequest) returns (polarsignals.featureflag.v1alpha1.AddUserFlagResponse);
     */
    addUserFlag(input: AddUserFlagRequest, options?: RpcOptions): UnaryCall<AddUserFlagRequest, AddUserFlagResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddUserFlagRequest, AddUserFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RemoveUserFlag removes a feature flag from a user.
     *
     * @generated from protobuf rpc: RemoveUserFlag(polarsignals.featureflag.v1alpha1.RemoveUserFlagRequest) returns (polarsignals.featureflag.v1alpha1.RemoveUserFlagResponse);
     */
    removeUserFlag(input: RemoveUserFlagRequest, options?: RpcOptions): UnaryCall<RemoveUserFlagRequest, RemoveUserFlagResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveUserFlagRequest, RemoveUserFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * FlagEnabled returns true if the feature flag is enabled for the user.
     *
     * @generated from protobuf rpc: FlagEnabled(polarsignals.featureflag.v1alpha1.FlagEnabledRequest) returns (polarsignals.featureflag.v1alpha1.FlagEnabledResponse);
     */
    flagEnabled(input: FlagEnabledRequest, options?: RpcOptions): UnaryCall<FlagEnabledRequest, FlagEnabledResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<FlagEnabledRequest, FlagEnabledResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ListFlags returns a list of feature flags.
     *
     * @generated from protobuf rpc: ListFlags(polarsignals.featureflag.v1alpha1.ListFlagsRequest) returns (polarsignals.featureflag.v1alpha1.ListFlagsResponse);
     */
    listFlags(input: ListFlagsRequest, options?: RpcOptions): UnaryCall<ListFlagsRequest, ListFlagsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListFlagsRequest, ListFlagsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * AddFlag adds a new feature flag.
     *
     * @generated from protobuf rpc: AddFlag(polarsignals.featureflag.v1alpha1.AddFlagRequest) returns (polarsignals.featureflag.v1alpha1.AddFlagResponse);
     */
    addFlag(input: AddFlagRequest, options?: RpcOptions): UnaryCall<AddFlagRequest, AddFlagResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddFlagRequest, AddFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * DeleteFlag deletes a feature flag.
     *
     * @generated from protobuf rpc: DeleteFlag(polarsignals.featureflag.v1alpha1.DeleteFlagRequest) returns (polarsignals.featureflag.v1alpha1.DeleteFlagResponse);
     */
    deleteFlag(input: DeleteFlagRequest, options?: RpcOptions): UnaryCall<DeleteFlagRequest, DeleteFlagResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteFlagRequest, DeleteFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ListOrganizationFlags returns a list of available feature flags for organizations.
     *
     * @generated from protobuf rpc: ListOrganizationFlags(polarsignals.featureflag.v1alpha1.ListOrganizationFlagsRequest) returns (polarsignals.featureflag.v1alpha1.ListOrganizationFlagsResponse);
     */
    listOrganizationFlags(input: ListOrganizationFlagsRequest, options?: RpcOptions): UnaryCall<ListOrganizationFlagsRequest, ListOrganizationFlagsResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOrganizationFlagsRequest, ListOrganizationFlagsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * ListOrganizations returns a list of organizations with feature flags.
     *
     * @generated from protobuf rpc: ListOrganizations(polarsignals.featureflag.v1alpha1.ListOrganizationsRequest) returns (polarsignals.featureflag.v1alpha1.ListOrganizationsResponse);
     */
    listOrganizations(input: ListOrganizationsRequest, options?: RpcOptions): UnaryCall<ListOrganizationsRequest, ListOrganizationsResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListOrganizationsRequest, ListOrganizationsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * AddOrganizationFlag adds a new feature flag to an organization.
     *
     * @generated from protobuf rpc: AddOrganizationFlag(polarsignals.featureflag.v1alpha1.AddOrganizationFlagRequest) returns (polarsignals.featureflag.v1alpha1.AddOrganizationFlagResponse);
     */
    addOrganizationFlag(input: AddOrganizationFlagRequest, options?: RpcOptions): UnaryCall<AddOrganizationFlagRequest, AddOrganizationFlagResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddOrganizationFlagRequest, AddOrganizationFlagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RemoveOrganizationFlag removes a feature flag from an organization.
     *
     * @generated from protobuf rpc: RemoveOrganizationFlag(polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagRequest) returns (polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagResponse);
     */
    removeOrganizationFlag(input: RemoveOrganizationFlagRequest, options?: RpcOptions): UnaryCall<RemoveOrganizationFlagRequest, RemoveOrganizationFlagResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveOrganizationFlagRequest, RemoveOrganizationFlagResponse>("unary", this._transport, method, opt, input);
    }
}
