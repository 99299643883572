// @generated by protobuf-ts 2.8.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "polarsignals/user/v1alpha1/user.proto" (package "polarsignals.user.v1alpha1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { UserService } from "./user";
import type { UpdateSelfResponse } from "./user";
import type { UpdateSelfRequest } from "./user";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetSelfResponse } from "./user";
import type { GetSelfRequest } from "./user";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * UserService provides the ability to manage users.
 *
 * @generated from protobuf service polarsignals.user.v1alpha1.UserService
 */
export interface IUserServiceClient {
    /**
     * GetSelf returns the user information for the authenticated user.
     *
     * @generated from protobuf rpc: GetSelf(polarsignals.user.v1alpha1.GetSelfRequest) returns (polarsignals.user.v1alpha1.GetSelfResponse);
     */
    getSelf(input: GetSelfRequest, options?: RpcOptions): UnaryCall<GetSelfRequest, GetSelfResponse>;
    /**
     * UpdateSelf updates the user information for the authenticated user.
     *
     * @generated from protobuf rpc: UpdateSelf(polarsignals.user.v1alpha1.UpdateSelfRequest) returns (polarsignals.user.v1alpha1.UpdateSelfResponse);
     */
    updateSelf(input: UpdateSelfRequest, options?: RpcOptions): UnaryCall<UpdateSelfRequest, UpdateSelfResponse>;
}
/**
 * UserService provides the ability to manage users.
 *
 * @generated from protobuf service polarsignals.user.v1alpha1.UserService
 */
export class UserServiceClient implements IUserServiceClient, ServiceInfo {
    typeName = UserService.typeName;
    methods = UserService.methods;
    options = UserService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetSelf returns the user information for the authenticated user.
     *
     * @generated from protobuf rpc: GetSelf(polarsignals.user.v1alpha1.GetSelfRequest) returns (polarsignals.user.v1alpha1.GetSelfResponse);
     */
    getSelf(input: GetSelfRequest, options?: RpcOptions): UnaryCall<GetSelfRequest, GetSelfResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSelfRequest, GetSelfResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UpdateSelf updates the user information for the authenticated user.
     *
     * @generated from protobuf rpc: UpdateSelf(polarsignals.user.v1alpha1.UpdateSelfRequest) returns (polarsignals.user.v1alpha1.UpdateSelfResponse);
     */
    updateSelf(input: UpdateSelfRequest, options?: RpcOptions): UnaryCall<UpdateSelfRequest, UpdateSelfResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateSelfRequest, UpdateSelfResponse>("unary", this._transport, method, opt, input);
    }
}
