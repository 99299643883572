import {PolarSignalsFull} from '@polarsignals/icons';
import {Box, Flex} from 'theme-ui';

import ColorModeToggle from 'components/Navbar/ColorModeToggle';

const NavbarLite = () => {
  return (
    <Box>
      <Flex className="justify-end p-2">
        <ColorModeToggle />
      </Flex>

      <Flex className="justify-center">
        <PolarSignalsFull height={40} width="auto" />
      </Flex>
    </Box>
  );
};

export default NavbarLite;
