import React from 'react';

import {QueryServiceClient} from '@parca/client';
import {BillingServiceClient} from '@polarsignals/client-grpc-web/polarsignals/billing/v1alpha1/billing.client';
import {FeatureFlagServiceClient} from '@polarsignals/client-grpc-web/polarsignals/featureflag/v1alpha1/featureflag.client';
import {OrganizationServiceClient} from '@polarsignals/client-grpc-web/polarsignals/organization/v1alpha1/organization.client';
import {ProjectServiceClient} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project.client';
import {UserServiceClient} from '@polarsignals/client-grpc-web/polarsignals/user/v1alpha1/user.client';
import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';

import {apiEndpoint} from '../../constants';

const GRPC_SERVICE_HOST = `${apiEndpoint}/api`;
const GRPC_TRANSPORT = new GrpcWebFetchTransport({
  baseUrl: GRPC_SERVICE_HOST,
  fetchInit: {credentials: 'include'},
  format: 'binary',
});

interface IGrpcContext {
  userServiceClient: UserServiceClient;
  parcaQueryClient: QueryServiceClient;
  projectServiceClient: ProjectServiceClient;
  organizationServiceClient: OrganizationServiceClient;
  featureFlagsClient: FeatureFlagServiceClient;
  billingServiceClient: BillingServiceClient;
}

const defaultValue: IGrpcContext = {
  userServiceClient: new UserServiceClient(GRPC_TRANSPORT),
  parcaQueryClient: new QueryServiceClient(GRPC_TRANSPORT),
  projectServiceClient: new ProjectServiceClient(GRPC_TRANSPORT),
  organizationServiceClient: new OrganizationServiceClient(GRPC_TRANSPORT),
  featureFlagsClient: new FeatureFlagServiceClient(GRPC_TRANSPORT),
  billingServiceClient: new BillingServiceClient(GRPC_TRANSPORT),
};

const GrpcContext = React.createContext<IGrpcContext>(defaultValue);

export const GrpcContextProvider = ({children}: {children: React.ReactNode}) => {
  return <GrpcContext.Provider value={defaultValue}>{children}</GrpcContext.Provider>;
};

export const useGrpcContext = () => {
  const context = React.useContext(GrpcContext);
  if (context === undefined) {
    throw new Error('useGrpcContext must be used within a GrpcContextProvider');
  }
  return context;
};

export default GrpcContext;
