/** @jsxImportSource theme-ui */
import React from 'react';

import cx from 'classnames';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import {ProjectSidebar} from 'components/Sidebar/ProjectSidebar';
import {useAppSelector} from 'store/hooks';
import {selectSidebarCollapsed} from 'store/slices/uiSlice';

export interface LayoutProps {
  children: React.ReactNode;
}

const LayoutLoggedIn: React.FC<LayoutProps> = ({children}) => {
  const isSidebarCollapsed = useAppSelector(selectSidebarCollapsed);

  return (
    <main
      className={cx(
        'grid-areas-layoutLoggedIn grid-cols-layoutLoggedIn grid-rows-layoutLoggedIn grid h-screen min-h-full gap-x-2 gap-y-[0.1rem] overflow-auto',
        isSidebarCollapsed ? 'grid-cols-layoutLoggedInCollapsed' : 'grid-cols-layoutLoggedIn'
      )}
    >
      <nav className="grid-in-navbar">
        <Navbar />
      </nav>
      <aside sx={{borderRight: '1px solid', borderColor: 'border'}} className="grid-in-sidebar">
        <ProjectSidebar />
      </aside>
      <section className="grid-in-main">{children}</section>
      <footer className="grid-in-footer pb-4 pt-4">
        <Footer />
      </footer>
    </main>
  );
};

export default LayoutLoggedIn;
