import React, {useEffect} from 'react';

import {Spinner} from '@polarsignals/components';
import {Analytics} from '@vercel/analytics/react';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import Script from 'next/script';
import {Toaster} from 'react-hot-toast';
import {QueryClient, QueryClientProvider} from 'react-query';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'theme-ui';

import UserLogin from 'components/UserLogin';
import {GrpcContextProvider} from 'contexts/GrpcContext';
import useCurrentUser from 'hooks/data/useCurrentUser';
import {AdminLayout} from 'layouts/Admin';
import ColorModeProvider from 'layouts/ColorModeProvider';
import LayoutLoggedIn, {LayoutProps} from 'layouts/LoggedIn';
import LayoutLoggedOut from 'layouts/LoggedOut';
import NoSidebarLayout from 'layouts/NoSidebar';
import store from 'store';
import theme from 'styles/theme';

import 'styles/globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, _) => {
        if (failureCount < 1) {
          return true;
        }
        return false;
      },
    },
  },
});

const loggedOutLayoutPaths: {[key: string]: boolean} = {'/user-invite/[tokenId]': true};
const pathnameToLayoutMap: {[key: string]: React.FC} = {
  '/user-invite/[tokenId]': LayoutLoggedOut,
  '/welcome': NoSidebarLayout,
  '/': NoSidebarLayout,
  '/admin': AdminLayout,
  '/admin/user-flags': AdminLayout,
  '/admin/org-flags': AdminLayout,
};

const getLayoutForPathname: (pathname: string) => React.FC<LayoutProps> = (pathname: string) => {
  return pathnameToLayoutMap[pathname] ?? LayoutLoggedIn;
};

function App({Component, pageProps}: AppProps) {
  const {
    data: {user},
    loading,
    error,
  } = useCurrentUser();
  const {pathname} = useRouter();

  useEffect(() => {
    if (!user?.email || typeof window === 'undefined') {
      return;
    }

    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    try {
      (window as any).heap.identify(user.email);
      (window as any).heap.addUserProperties({name: user.name, company: user.company});
    } catch (error) {
      console.error(error);
    }
  }, [user?.email, user?.name, user?.company]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner size={100} />
      </div>
    );
  }

  if (error) {
    console.error('Error while checking login status', error);
  }

  if (!user && !loggedOutLayoutPaths[pathname]) {
    return (
      <LayoutLoggedOut>
        <UserLogin />
      </LayoutLoggedOut>
    );
  }

  const Layout = getLayoutForPathname(pathname);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

const AppWithProviders = (props: AppProps) => {
  const {store: reduxStore} = store();

  return (
    <Provider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <GrpcContextProvider>
          <ThemeProvider theme={theme}>
            <ColorModeProvider>
              <Head>
                <title>Polar Signals</title>
                <link rel="icon" href="/favicon.svg" />
              </Head>
              <Script
                id="heap"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                heap.load(${process.env.NEXT_PUBLIC_HEAP_APP_ID});`,
                }}
              />
              <App {...props} />
              <Toaster position="top-right" />
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `window._mfq = window._mfq || [];
              (function() {
                if ('${process.env.NEXT_PUBLIC_VERCEL_ENV}' !== 'production') {
                  return;
                }
                var mf = document.createElement("script");
                mf.type = "text/javascript"; mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/9dcc5804-39e0-402f-a0aa-35f1d575b341.js";
                document.getElementsByTagName("head")[0].appendChild(mf);
              })();`,
                }}
              ></script>
            </ColorModeProvider>
            <Analytics />
          </ThemeProvider>
        </GrpcContextProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppWithProviders;
