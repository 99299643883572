// @generated by protobuf-ts 2.8.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "polarsignals/organization/v1alpha1/organization.proto" (package "polarsignals.organization.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "../../user/v1alpha1/user";
/**
 * Organization represents an organization.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.Organization
 */
export interface Organization {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * the feature flags enabled for the user.
     *
     * @generated from protobuf field: repeated string feature_flags = 3;
     */
    featureFlags: string[];
}
/**
 * InviteToken represents an invite token.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.InviteToken
 */
export interface InviteToken {
    /**
     * The ID of the invite token.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string organization_id = 2;
     */
    organizationId: string;
    /**
     * The role of the user.
     *
     * @generated from protobuf field: string role = 3;
     */
    role: string;
}
/**
 * GetOrganizationsRequest is the request for the GetOrganizations method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationsRequest
 */
export interface GetOrganizationsRequest {
}
/**
 * GetOrganizationsResponse is the response for the GetOrganizations method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationsResponse
 */
export interface GetOrganizationsResponse {
    /**
     * The organizations.
     *
     * @generated from protobuf field: repeated polarsignals.organization.v1alpha1.Organization organizations = 1;
     */
    organizations: Organization[];
}
/**
 * CreateOrganizationRequest is the request for the CreateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * CreateOrganizationResponse is the response for the CreateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.CreateOrganizationResponse
 */
export interface CreateOrganizationResponse {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetOrganizationRequest is the request for the GetOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationRequest
 */
export interface GetOrganizationRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetOrganizationResponse is the response for the GetOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationResponse
 */
export interface GetOrganizationResponse {
    /**
     * The organization.
     *
     * @generated from protobuf field: polarsignals.organization.v1alpha1.Organization organization = 1;
     */
    organization?: Organization;
}
/**
 * UpdateOrganizationRequest is the request for the UpdateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the organization.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * UpdateOrganizationResponse is the response for the UpdateOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationResponse
 */
export interface UpdateOrganizationResponse {
}
/**
 * DeleteOrganizationRequest is the request for the DeleteOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationRequest
 */
export interface DeleteOrganizationRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * DeleteOrganizationResponse is the response for the DeleteOrganization method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationResponse
 */
export interface DeleteOrganizationResponse {
}
/**
 * GetOrganizationUsersRequest is the request for the GetOrganizationUsers method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersRequest
 */
export interface GetOrganizationUsersRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetOrganizationUsersResponse is the response for the GetOrganizationUsers method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersResponse
 */
export interface GetOrganizationUsersResponse {
    /**
     * The users.
     *
     * @generated from protobuf field: repeated polarsignals.user.v1alpha1.User users = 1;
     */
    users: User[];
}
/**
 * AddOrganizationUserRequest is the request for the AddOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserRequest
 */
export interface AddOrganizationUserRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the user.
     *
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * AddOrganizationUserResponse is the response for the AddOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserResponse
 */
export interface AddOrganizationUserResponse {
}
/**
 * RemoveOrganizationUserRequest is the request for the RemoveOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest
 */
export interface RemoveOrganizationUserRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the user.
     *
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
}
/**
 * RemoveOrganizationUserResponse is the response for the RemoveOrganizationUser method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse
 */
export interface RemoveOrganizationUserResponse {
}
/**
 * IsInviteTokenValidRequest is the request for the IsInviteTokenValid method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidRequest
 */
export interface IsInviteTokenValidRequest {
    /**
     * The invite token.
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * IsInviteTokenValidResponse is the response for the IsInviteTokenValid method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidResponse
 */
export interface IsInviteTokenValidResponse {
    /**
     * The organization name.
     *
     * @generated from protobuf field: string organization_name = 1;
     */
    organizationName: string;
}
/**
 * AcceptInviteTokenRequest is the request for the AcceptInviteToken method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenRequest
 */
export interface AcceptInviteTokenRequest {
    /**
     * The invite token.
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * AcceptInviteTokenResponse is the response for the AcceptInviteToken method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenResponse
 */
export interface AcceptInviteTokenResponse {
}
/**
 * GetInviteTokensRequest is the request for the GetInviteTokens method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetInviteTokensRequest
 */
export interface GetInviteTokensRequest {
    /**
     * The ID of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * GetInviteTokensResponse is the response for the GetInviteTokens method.
 *
 * @generated from protobuf message polarsignals.organization.v1alpha1.GetInviteTokensResponse
 */
export interface GetInviteTokensResponse {
    /**
     * The invite tokens.
     *
     * @generated from protobuf field: repeated polarsignals.organization.v1alpha1.InviteToken invite_tokens = 1;
     */
    inviteTokens: InviteToken[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Organization$Type extends MessageType<Organization> {
    constructor() {
        super("polarsignals.organization.v1alpha1.Organization", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "feature_flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Organization>): Organization {
        const message = { id: "", name: "", featureFlags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Organization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Organization): Organization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated string feature_flags */ 3:
                    message.featureFlags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Organization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated string feature_flags = 3; */
        for (let i = 0; i < message.featureFlags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.featureFlags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.Organization
 */
export const Organization = new Organization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InviteToken$Type extends MessageType<InviteToken> {
    constructor() {
        super("polarsignals.organization.v1alpha1.InviteToken", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organization_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InviteToken>): InviteToken {
        const message = { id: "", organizationId: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InviteToken>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InviteToken): InviteToken {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string organization_id */ 2:
                    message.organizationId = reader.string();
                    break;
                case /* string role */ 3:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InviteToken, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string organization_id = 2; */
        if (message.organizationId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.organizationId);
        /* string role = 3; */
        if (message.role !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.InviteToken
 */
export const InviteToken = new InviteToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationsRequest$Type extends MessageType<GetOrganizationsRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationsRequest", []);
    }
    create(value?: PartialMessage<GetOrganizationsRequest>): GetOrganizationsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationsRequest): GetOrganizationsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetOrganizationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationsRequest
 */
export const GetOrganizationsRequest = new GetOrganizationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationsResponse$Type extends MessageType<GetOrganizationsResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationsResponse", [
            { no: 1, name: "organizations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Organization }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationsResponse>): GetOrganizationsResponse {
        const message = { organizations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationsResponse): GetOrganizationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.organization.v1alpha1.Organization organizations */ 1:
                    message.organizations.push(Organization.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.organization.v1alpha1.Organization organizations = 1; */
        for (let i = 0; i < message.organizations.length; i++)
            Organization.internalBinaryWrite(message.organizations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationsResponse
 */
export const GetOrganizationsResponse = new GetOrganizationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOrganizationRequest$Type extends MessageType<CreateOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.CreateOrganizationRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateOrganizationRequest>): CreateOrganizationRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOrganizationRequest): CreateOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.CreateOrganizationRequest
 */
export const CreateOrganizationRequest = new CreateOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateOrganizationResponse$Type extends MessageType<CreateOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.CreateOrganizationResponse", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateOrganizationResponse>): CreateOrganizationResponse {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateOrganizationResponse): CreateOrganizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.CreateOrganizationResponse
 */
export const CreateOrganizationResponse = new CreateOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationRequest$Type extends MessageType<GetOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationRequest>): GetOrganizationRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationRequest): GetOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationRequest
 */
export const GetOrganizationRequest = new GetOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationResponse$Type extends MessageType<GetOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationResponse", [
            { no: 1, name: "organization", kind: "message", T: () => Organization }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationResponse>): GetOrganizationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationResponse): GetOrganizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* polarsignals.organization.v1alpha1.Organization organization */ 1:
                    message.organization = Organization.internalBinaryRead(reader, reader.uint32(), options, message.organization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* polarsignals.organization.v1alpha1.Organization organization = 1; */
        if (message.organization)
            Organization.internalBinaryWrite(message.organization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationResponse
 */
export const GetOrganizationResponse = new GetOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationRequest$Type extends MessageType<UpdateOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.UpdateOrganizationRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateOrganizationRequest>): UpdateOrganizationRequest {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationRequest): UpdateOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationRequest
 */
export const UpdateOrganizationRequest = new UpdateOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationResponse$Type extends MessageType<UpdateOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.UpdateOrganizationResponse", []);
    }
    create(value?: PartialMessage<UpdateOrganizationResponse>): UpdateOrganizationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationResponse): UpdateOrganizationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.UpdateOrganizationResponse
 */
export const UpdateOrganizationResponse = new UpdateOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOrganizationRequest$Type extends MessageType<DeleteOrganizationRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.DeleteOrganizationRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteOrganizationRequest>): DeleteOrganizationRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteOrganizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOrganizationRequest): DeleteOrganizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteOrganizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationRequest
 */
export const DeleteOrganizationRequest = new DeleteOrganizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOrganizationResponse$Type extends MessageType<DeleteOrganizationResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.DeleteOrganizationResponse", []);
    }
    create(value?: PartialMessage<DeleteOrganizationResponse>): DeleteOrganizationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteOrganizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOrganizationResponse): DeleteOrganizationResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteOrganizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.DeleteOrganizationResponse
 */
export const DeleteOrganizationResponse = new DeleteOrganizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationUsersRequest$Type extends MessageType<GetOrganizationUsersRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationUsersRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationUsersRequest>): GetOrganizationUsersRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationUsersRequest): GetOrganizationUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersRequest
 */
export const GetOrganizationUsersRequest = new GetOrganizationUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrganizationUsersResponse$Type extends MessageType<GetOrganizationUsersResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetOrganizationUsersResponse", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetOrganizationUsersResponse>): GetOrganizationUsersResponse {
        const message = { users: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOrganizationUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOrganizationUsersResponse): GetOrganizationUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.user.v1alpha1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOrganizationUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.user.v1alpha1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetOrganizationUsersResponse
 */
export const GetOrganizationUsersResponse = new GetOrganizationUsersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOrganizationUserRequest$Type extends MessageType<AddOrganizationUserRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AddOrganizationUserRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddOrganizationUserRequest>): AddOrganizationUserRequest {
        const message = { id: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddOrganizationUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOrganizationUserRequest): AddOrganizationUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOrganizationUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserRequest
 */
export const AddOrganizationUserRequest = new AddOrganizationUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOrganizationUserResponse$Type extends MessageType<AddOrganizationUserResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AddOrganizationUserResponse", []);
    }
    create(value?: PartialMessage<AddOrganizationUserResponse>): AddOrganizationUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddOrganizationUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOrganizationUserResponse): AddOrganizationUserResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddOrganizationUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AddOrganizationUserResponse
 */
export const AddOrganizationUserResponse = new AddOrganizationUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveOrganizationUserRequest$Type extends MessageType<RemoveOrganizationUserRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveOrganizationUserRequest>): RemoveOrganizationUserRequest {
        const message = { id: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveOrganizationUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveOrganizationUserRequest): RemoveOrganizationUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveOrganizationUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserRequest
 */
export const RemoveOrganizationUserRequest = new RemoveOrganizationUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveOrganizationUserResponse$Type extends MessageType<RemoveOrganizationUserResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse", []);
    }
    create(value?: PartialMessage<RemoveOrganizationUserResponse>): RemoveOrganizationUserResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveOrganizationUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveOrganizationUserResponse): RemoveOrganizationUserResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveOrganizationUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.RemoveOrganizationUserResponse
 */
export const RemoveOrganizationUserResponse = new RemoveOrganizationUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsInviteTokenValidRequest$Type extends MessageType<IsInviteTokenValidRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.IsInviteTokenValidRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IsInviteTokenValidRequest>): IsInviteTokenValidRequest {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IsInviteTokenValidRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsInviteTokenValidRequest): IsInviteTokenValidRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsInviteTokenValidRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidRequest
 */
export const IsInviteTokenValidRequest = new IsInviteTokenValidRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsInviteTokenValidResponse$Type extends MessageType<IsInviteTokenValidResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.IsInviteTokenValidResponse", [
            { no: 1, name: "organization_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IsInviteTokenValidResponse>): IsInviteTokenValidResponse {
        const message = { organizationName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IsInviteTokenValidResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IsInviteTokenValidResponse): IsInviteTokenValidResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string organization_name */ 1:
                    message.organizationName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IsInviteTokenValidResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string organization_name = 1; */
        if (message.organizationName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.organizationName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.IsInviteTokenValidResponse
 */
export const IsInviteTokenValidResponse = new IsInviteTokenValidResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptInviteTokenRequest$Type extends MessageType<AcceptInviteTokenRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AcceptInviteTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptInviteTokenRequest>): AcceptInviteTokenRequest {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcceptInviteTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptInviteTokenRequest): AcceptInviteTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptInviteTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenRequest
 */
export const AcceptInviteTokenRequest = new AcceptInviteTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptInviteTokenResponse$Type extends MessageType<AcceptInviteTokenResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.AcceptInviteTokenResponse", []);
    }
    create(value?: PartialMessage<AcceptInviteTokenResponse>): AcceptInviteTokenResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcceptInviteTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptInviteTokenResponse): AcceptInviteTokenResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptInviteTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.AcceptInviteTokenResponse
 */
export const AcceptInviteTokenResponse = new AcceptInviteTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInviteTokensRequest$Type extends MessageType<GetInviteTokensRequest> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetInviteTokensRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInviteTokensRequest>): GetInviteTokensRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInviteTokensRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInviteTokensRequest): GetInviteTokensRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInviteTokensRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetInviteTokensRequest
 */
export const GetInviteTokensRequest = new GetInviteTokensRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInviteTokensResponse$Type extends MessageType<GetInviteTokensResponse> {
    constructor() {
        super("polarsignals.organization.v1alpha1.GetInviteTokensResponse", [
            { no: 1, name: "invite_tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InviteToken }
        ]);
    }
    create(value?: PartialMessage<GetInviteTokensResponse>): GetInviteTokensResponse {
        const message = { inviteTokens: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInviteTokensResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInviteTokensResponse): GetInviteTokensResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.organization.v1alpha1.InviteToken invite_tokens */ 1:
                    message.inviteTokens.push(InviteToken.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInviteTokensResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.organization.v1alpha1.InviteToken invite_tokens = 1; */
        for (let i = 0; i < message.inviteTokens.length; i++)
            InviteToken.internalBinaryWrite(message.inviteTokens[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.organization.v1alpha1.GetInviteTokensResponse
 */
export const GetInviteTokensResponse = new GetInviteTokensResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.organization.v1alpha1.OrganizationService
 */
export const OrganizationService = new ServiceType("polarsignals.organization.v1alpha1.OrganizationService", [
    { name: "GetOrganizations", options: { "google.api.http": { get: "/v1" } }, I: GetOrganizationsRequest, O: GetOrganizationsResponse },
    { name: "CreateOrganization", options: { "google.api.http": { post: "/v1", body: "*" } }, I: CreateOrganizationRequest, O: CreateOrganizationResponse },
    { name: "GetOrganization", options: { "google.api.http": { get: "/v1/{id}" } }, I: GetOrganizationRequest, O: GetOrganizationResponse },
    { name: "UpdateOrganization", options: { "google.api.http": { patch: "/v1/{id}", body: "*" } }, I: UpdateOrganizationRequest, O: UpdateOrganizationResponse },
    { name: "DeleteOrganization", options: { "google.api.http": { delete: "/v1/{id}" } }, I: DeleteOrganizationRequest, O: DeleteOrganizationResponse },
    { name: "GetOrganizationUsers", options: { "google.api.http": { get: "/v1/{id}/users" } }, I: GetOrganizationUsersRequest, O: GetOrganizationUsersResponse },
    { name: "AddOrganizationUser", options: { "google.api.http": { post: "/v1/{id}/users", body: "*" } }, I: AddOrganizationUserRequest, O: AddOrganizationUserResponse },
    { name: "RemoveOrganizationUser", options: { "google.api.http": { delete: "/v1/{id}/users" } }, I: RemoveOrganizationUserRequest, O: RemoveOrganizationUserResponse },
    { name: "IsInviteTokenValid", options: { "google.api.http": { post: "/v1/invite-token-validity/{token}" } }, I: IsInviteTokenValidRequest, O: IsInviteTokenValidResponse },
    { name: "AcceptInviteToken", options: { "google.api.http": { post: "/v1/accept-invite-token/{token}", body: "*" } }, I: AcceptInviteTokenRequest, O: AcceptInviteTokenResponse },
    { name: "GetInviteTokens", options: { "google.api.http": { get: "/v1/{id}/invite-tokens" } }, I: GetInviteTokensRequest, O: GetInviteTokensResponse }
]);
