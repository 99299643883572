// @generated by protobuf-ts 2.8.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "polarsignals/featureflag/v1alpha1/featureflag.proto" (package "polarsignals.featureflag.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Organization } from "../../organization/v1alpha1/organization";
import { User } from "../../user/v1alpha1/user";
/**
 * ListFlagsRequest is the request for the ListFlags method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListFlagsRequest
 */
export interface ListFlagsRequest {
    /**
     * the token of the page that retrived
     *
     * @generated from protobuf field: string page_token = 1;
     */
    pageToken: string;
    /**
     * the number of items to return per page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
}
/**
 * ListFlagsResponse is the response for the ListFlags method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListFlagsResponse
 */
export interface ListFlagsResponse {
    /**
     * token of the next page for pagination if more pages exist
     *
     * @generated from protobuf field: string next_page_token = 1;
     */
    nextPageToken: string;
    /**
     * the list of flags
     *
     * @generated from protobuf field: repeated string flags = 2;
     */
    flags: string[];
}
/**
 * FlagEnabledRequest is the request for the FlagEnabled method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.FlagEnabledRequest
 */
export interface FlagEnabledRequest {
    /**
     * the user id
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * the feature flag name
     *
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * FlagEnabledResponse is the response for the FlagEnabled method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.FlagEnabledResponse
 */
export interface FlagEnabledResponse {
    /**
     * true if the feature flag is enabled for the user
     *
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
}
/**
 * RemoveUserFlagRequest is the request for the RemoveUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.RemoveUserFlagRequest
 */
export interface RemoveUserFlagRequest {
    /**
     * id for the user to remove the flag from
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * the feature flag name
     *
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * RemoveUserFlagResponse is the response for the RemoveUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.RemoveUserFlagResponse
 */
export interface RemoveUserFlagResponse {
}
/**
 * AddUserFlagRequest is the request for the AddUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.DeleteFlagRequest
 */
export interface DeleteFlagRequest {
    /**
     * the feature flag name
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * DeleteFlagResponse is the response for the DeleteFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.DeleteFlagResponse
 */
export interface DeleteFlagResponse {
}
/**
 * AddUserFlagRequest is the request for the AddUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.AddFlagRequest
 */
export interface AddFlagRequest {
    /**
     * the feature flag name
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * AddUserFlagResponse is the response for the AddUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.AddFlagResponse
 */
export interface AddFlagResponse {
}
/**
 * AddUserFlagRequest is the request for the AddUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.AddUserFlagRequest
 */
export interface AddUserFlagRequest {
    /**
     * id of the user to add
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * flag to add to the user
     *
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * AddUserFlagResponse is the response for the AddUserFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.AddUserFlagResponse
 */
export interface AddUserFlagResponse {
}
/**
 * ListUsersRequest is the request for the ListUsers method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListUsersRequest
 */
export interface ListUsersRequest {
    /**
     * the token of the page that retrived
     *
     * @generated from protobuf field: string page_token = 1;
     */
    pageToken: string;
    /**
     * the number of items to return per page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
}
/**
 * ListUsersResponse is the response for the ListUsers method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListUsersResponse
 */
export interface ListUsersResponse {
    /**
     * pagination if empty no more pages exist
     *
     * @generated from protobuf field: string next_page_token = 1;
     */
    nextPageToken: string;
    /**
     * the list of users
     *
     * @generated from protobuf field: repeated polarsignals.user.v1alpha1.User users = 2;
     */
    users: User[];
    /**
     * the total number of users
     *
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
}
/**
 * ListOrganizationsRequest is the request for the ListOrganizations method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationsRequest
 */
export interface ListOrganizationsRequest {
    /**
     * the token of the page that retrived
     *
     * @generated from protobuf field: string page_token = 1;
     */
    pageToken: string;
    /**
     * the number of items to return per page
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
}
/**
 * ListOrganizationsResponse is the response for the ListOrganizations method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationsResponse
 */
export interface ListOrganizationsResponse {
    /**
     * pagination if empty no more pages exist
     *
     * @generated from protobuf field: string next_page_token = 1;
     */
    nextPageToken: string;
    /**
     * the list of organizations
     *
     * @generated from protobuf field: repeated polarsignals.organization.v1alpha1.Organization organizations = 2;
     */
    organizations: Organization[];
    /**
     * the total number of organizations
     *
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
}
/**
 * AddOrganizationFlagRequest is the request for the AddOrganizationFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.AddOrganizationFlagRequest
 */
export interface AddOrganizationFlagRequest {
    /**
     * id of the organization to add
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * flag to add to the organization
     *
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * AddOrganizationFlagResponse is the response for the AddOrganizationFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.AddOrganizationFlagResponse
 */
export interface AddOrganizationFlagResponse {
}
/**
 * RemoveOrganizationFlagRequest is the request for the RemoveOrganizationFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagRequest
 */
export interface RemoveOrganizationFlagRequest {
    /**
     * id for the organization to remove the flag from
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * the feature flag name
     *
     * @generated from protobuf field: string flag = 2;
     */
    flag: string;
}
/**
 * RemoveOrganizationFlagResponse is the response for the RemoveOrganizationFlag method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagResponse
 */
export interface RemoveOrganizationFlagResponse {
}
/**
 * ListOrganizationFlagsRequest is the request for the ListOrganizationFlags method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationFlagsRequest
 */
export interface ListOrganizationFlagsRequest {
}
/**
 * ListOrganizationFlagsResponse is the response for the ListOrganizationFlags method.
 *
 * @generated from protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationFlagsResponse
 */
export interface ListOrganizationFlagsResponse {
    /**
     * the list of flags
     *
     * @generated from protobuf field: repeated string flags = 1;
     */
    flags: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ListFlagsRequest$Type extends MessageType<ListFlagsRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListFlagsRequest", [
            { no: 1, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListFlagsRequest>): ListFlagsRequest {
        const message = { pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListFlagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFlagsRequest): ListFlagsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string page_token */ 1:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFlagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string page_token = 1; */
        if (message.pageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListFlagsRequest
 */
export const ListFlagsRequest = new ListFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFlagsResponse$Type extends MessageType<ListFlagsResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListFlagsResponse", [
            { no: 1, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListFlagsResponse>): ListFlagsResponse {
        const message = { nextPageToken: "", flags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListFlagsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFlagsResponse): ListFlagsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_token */ 1:
                    message.nextPageToken = reader.string();
                    break;
                case /* repeated string flags */ 2:
                    message.flags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFlagsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_page_token = 1; */
        if (message.nextPageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageToken);
        /* repeated string flags = 2; */
        for (let i = 0; i < message.flags.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.flags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListFlagsResponse
 */
export const ListFlagsResponse = new ListFlagsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlagEnabledRequest$Type extends MessageType<FlagEnabledRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.FlagEnabledRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FlagEnabledRequest>): FlagEnabledRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlagEnabledRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlagEnabledRequest): FlagEnabledRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlagEnabledRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.FlagEnabledRequest
 */
export const FlagEnabledRequest = new FlagEnabledRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlagEnabledResponse$Type extends MessageType<FlagEnabledResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.FlagEnabledResponse", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FlagEnabledResponse>): FlagEnabledResponse {
        const message = { enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlagEnabledResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlagEnabledResponse): FlagEnabledResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlagEnabledResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.FlagEnabledResponse
 */
export const FlagEnabledResponse = new FlagEnabledResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserFlagRequest$Type extends MessageType<RemoveUserFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.RemoveUserFlagRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveUserFlagRequest>): RemoveUserFlagRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveUserFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserFlagRequest): RemoveUserFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveUserFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.RemoveUserFlagRequest
 */
export const RemoveUserFlagRequest = new RemoveUserFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserFlagResponse$Type extends MessageType<RemoveUserFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.RemoveUserFlagResponse", []);
    }
    create(value?: PartialMessage<RemoveUserFlagResponse>): RemoveUserFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveUserFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserFlagResponse): RemoveUserFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveUserFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.RemoveUserFlagResponse
 */
export const RemoveUserFlagResponse = new RemoveUserFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFlagRequest$Type extends MessageType<DeleteFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.DeleteFlagRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteFlagRequest>): DeleteFlagRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFlagRequest): DeleteFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.DeleteFlagRequest
 */
export const DeleteFlagRequest = new DeleteFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFlagResponse$Type extends MessageType<DeleteFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.DeleteFlagResponse", []);
    }
    create(value?: PartialMessage<DeleteFlagResponse>): DeleteFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFlagResponse): DeleteFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.DeleteFlagResponse
 */
export const DeleteFlagResponse = new DeleteFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddFlagRequest$Type extends MessageType<AddFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.AddFlagRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddFlagRequest>): AddFlagRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddFlagRequest): AddFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.AddFlagRequest
 */
export const AddFlagRequest = new AddFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddFlagResponse$Type extends MessageType<AddFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.AddFlagResponse", []);
    }
    create(value?: PartialMessage<AddFlagResponse>): AddFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddFlagResponse): AddFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.AddFlagResponse
 */
export const AddFlagResponse = new AddFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddUserFlagRequest$Type extends MessageType<AddUserFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.AddUserFlagRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddUserFlagRequest>): AddUserFlagRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddUserFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddUserFlagRequest): AddUserFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddUserFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.AddUserFlagRequest
 */
export const AddUserFlagRequest = new AddUserFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddUserFlagResponse$Type extends MessageType<AddUserFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.AddUserFlagResponse", []);
    }
    create(value?: PartialMessage<AddUserFlagResponse>): AddUserFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddUserFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddUserFlagResponse): AddUserFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddUserFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.AddUserFlagResponse
 */
export const AddUserFlagResponse = new AddUserFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersRequest$Type extends MessageType<ListUsersRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListUsersRequest", [
            { no: 1, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListUsersRequest>): ListUsersRequest {
        const message = { pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUsersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersRequest): ListUsersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string page_token */ 1:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string page_token = 1; */
        if (message.pageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListUsersRequest
 */
export const ListUsersRequest = new ListUsersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersResponse$Type extends MessageType<ListUsersResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListUsersResponse", [
            { no: 1, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListUsersResponse>): ListUsersResponse {
        const message = { nextPageToken: "", users: [], total: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUsersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersResponse): ListUsersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_token */ 1:
                    message.nextPageToken = reader.string();
                    break;
                case /* repeated polarsignals.user.v1alpha1.User users */ 2:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_page_token = 1; */
        if (message.nextPageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageToken);
        /* repeated polarsignals.user.v1alpha1.User users = 2; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListUsersResponse
 */
export const ListUsersResponse = new ListUsersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOrganizationsRequest$Type extends MessageType<ListOrganizationsRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListOrganizationsRequest", [
            { no: 1, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListOrganizationsRequest>): ListOrganizationsRequest {
        const message = { pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListOrganizationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListOrganizationsRequest): ListOrganizationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string page_token */ 1:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListOrganizationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string page_token = 1; */
        if (message.pageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationsRequest
 */
export const ListOrganizationsRequest = new ListOrganizationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOrganizationsResponse$Type extends MessageType<ListOrganizationsResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListOrganizationsResponse", [
            { no: 1, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "organizations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Organization },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListOrganizationsResponse>): ListOrganizationsResponse {
        const message = { nextPageToken: "", organizations: [], total: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListOrganizationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListOrganizationsResponse): ListOrganizationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_token */ 1:
                    message.nextPageToken = reader.string();
                    break;
                case /* repeated polarsignals.organization.v1alpha1.Organization organizations */ 2:
                    message.organizations.push(Organization.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListOrganizationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_page_token = 1; */
        if (message.nextPageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageToken);
        /* repeated polarsignals.organization.v1alpha1.Organization organizations = 2; */
        for (let i = 0; i < message.organizations.length; i++)
            Organization.internalBinaryWrite(message.organizations[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationsResponse
 */
export const ListOrganizationsResponse = new ListOrganizationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOrganizationFlagRequest$Type extends MessageType<AddOrganizationFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.AddOrganizationFlagRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddOrganizationFlagRequest>): AddOrganizationFlagRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddOrganizationFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOrganizationFlagRequest): AddOrganizationFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOrganizationFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.AddOrganizationFlagRequest
 */
export const AddOrganizationFlagRequest = new AddOrganizationFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOrganizationFlagResponse$Type extends MessageType<AddOrganizationFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.AddOrganizationFlagResponse", []);
    }
    create(value?: PartialMessage<AddOrganizationFlagResponse>): AddOrganizationFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddOrganizationFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOrganizationFlagResponse): AddOrganizationFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddOrganizationFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.AddOrganizationFlagResponse
 */
export const AddOrganizationFlagResponse = new AddOrganizationFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveOrganizationFlagRequest$Type extends MessageType<RemoveOrganizationFlagRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "flag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveOrganizationFlagRequest>): RemoveOrganizationFlagRequest {
        const message = { id: "", flag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveOrganizationFlagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveOrganizationFlagRequest): RemoveOrganizationFlagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string flag */ 2:
                    message.flag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveOrganizationFlagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string flag = 2; */
        if (message.flag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.flag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagRequest
 */
export const RemoveOrganizationFlagRequest = new RemoveOrganizationFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveOrganizationFlagResponse$Type extends MessageType<RemoveOrganizationFlagResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagResponse", []);
    }
    create(value?: PartialMessage<RemoveOrganizationFlagResponse>): RemoveOrganizationFlagResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveOrganizationFlagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveOrganizationFlagResponse): RemoveOrganizationFlagResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveOrganizationFlagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.RemoveOrganizationFlagResponse
 */
export const RemoveOrganizationFlagResponse = new RemoveOrganizationFlagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOrganizationFlagsRequest$Type extends MessageType<ListOrganizationFlagsRequest> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListOrganizationFlagsRequest", []);
    }
    create(value?: PartialMessage<ListOrganizationFlagsRequest>): ListOrganizationFlagsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListOrganizationFlagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListOrganizationFlagsRequest): ListOrganizationFlagsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListOrganizationFlagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationFlagsRequest
 */
export const ListOrganizationFlagsRequest = new ListOrganizationFlagsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListOrganizationFlagsResponse$Type extends MessageType<ListOrganizationFlagsResponse> {
    constructor() {
        super("polarsignals.featureflag.v1alpha1.ListOrganizationFlagsResponse", [
            { no: 1, name: "flags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListOrganizationFlagsResponse>): ListOrganizationFlagsResponse {
        const message = { flags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListOrganizationFlagsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListOrganizationFlagsResponse): ListOrganizationFlagsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string flags */ 1:
                    message.flags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListOrganizationFlagsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string flags = 1; */
        for (let i = 0; i < message.flags.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.flags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.featureflag.v1alpha1.ListOrganizationFlagsResponse
 */
export const ListOrganizationFlagsResponse = new ListOrganizationFlagsResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.featureflag.v1alpha1.FeatureFlagService
 */
export const FeatureFlagService = new ServiceType("polarsignals.featureflag.v1alpha1.FeatureFlagService", [
    { name: "ListUsers", options: { "google.api.http": { get: "/v1/users" } }, I: ListUsersRequest, O: ListUsersResponse },
    { name: "AddUserFlag", options: { "google.api.http": { post: "/v1/users/{id}/{flag}" } }, I: AddUserFlagRequest, O: AddUserFlagResponse },
    { name: "RemoveUserFlag", options: { "google.api.http": { delete: "/v1/users/{id}" } }, I: RemoveUserFlagRequest, O: RemoveUserFlagResponse },
    { name: "FlagEnabled", options: { "google.api.http": { get: "/v1/users/{id}/{flag}" } }, I: FlagEnabledRequest, O: FlagEnabledResponse },
    { name: "ListFlags", options: { "google.api.http": { get: "/v1/flags" } }, I: ListFlagsRequest, O: ListFlagsResponse },
    { name: "AddFlag", options: { "google.api.http": { post: "/v1/flags/{name}" } }, I: AddFlagRequest, O: AddFlagResponse },
    { name: "DeleteFlag", options: { "google.api.http": { delete: "/v1/flags/{name}" } }, I: DeleteFlagRequest, O: DeleteFlagResponse },
    { name: "ListOrganizationFlags", options: { "google.api.http": { get: "/v1/organizationflags" } }, I: ListOrganizationFlagsRequest, O: ListOrganizationFlagsResponse },
    { name: "ListOrganizations", options: { "google.api.http": { get: "/v1/organizations" } }, I: ListOrganizationsRequest, O: ListOrganizationsResponse },
    { name: "AddOrganizationFlag", options: { "google.api.http": { post: "/v1/organizations/{id}/{flag}" } }, I: AddOrganizationFlagRequest, O: AddOrganizationFlagResponse },
    { name: "RemoveOrganizationFlag", options: { "google.api.http": { delete: "/v1/organizations/{id}/{flag}" } }, I: RemoveOrganizationFlagRequest, O: RemoveOrganizationFlagResponse }
]);
