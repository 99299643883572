import {Fragment} from 'react';

import {Menu, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';

import Avatar from 'components/Avatar';
import useActiveProject from 'hooks/data/useActiveProject';
import useCurrentUser, {isAdminUser} from 'hooks/data/useCurrentUser';

import {logoutEndpoint} from '../../../constants';
import LinkWithIcon from './LinkWithIcon';

const getLinks = (isAdmin: boolean, currentOrganizationId: string) => [
  {
    name: 'Organization Settings',
    href: `/organizations/${currentOrganizationId}`,
    icon: 'eos-icons:organization',
    isVisible: !!currentOrganizationId,
  },
  {
    name: 'User Profile',
    href: '/user-profile',
    icon: 'ri:profile-fill',
    isVisible: true,
  },
  {
    name: 'Admin',
    href: '/admin',
    icon: 'heroicons-solid:users',
    isVisible: isAdmin,
  },
];

const ProfileInitials = () => {
  const {
    data: {user},
  } = useCurrentUser();
  const {
    data: {activeProject},
  } = useActiveProject();

  const userName = user?.name ?? '';
  const userEmail = user?.email ?? '';

  const isAdmin = isAdminUser(user);

  const logout = () => {
    fetch(logoutEndpoint, {credentials: 'include', redirect: 'manual'})
      .then(() => {
        console.log('Logout Successful');
      })
      .catch(err => {
        console.error('Something went wrong', err);
      })
      .finally(() => {
        window.location.href = '/';
      });
  };

  return (
    <Menu as="div" className="relative inline-block p-1 text-left">
      <div>
        <Menu.Button>
          <Avatar name={userName} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700">
          <div className="px-4 py-3">
            <p className="text-sm dark:text-white ">{userName}</p>
            <p className="break-words text-sm font-medium dark:text-gray-300">{userEmail}</p>
          </div>
          <div className="px-1 py-1">
            {getLinks(isAdmin, activeProject?.organizationId ?? '').map(
              (link, index) =>
                link.isVisible && (
                  <Menu.Item key={index}>
                    {({active}) => (
                      <LinkWithIcon
                        name={link.name}
                        href={link.href}
                        icon={link.icon}
                        isActive={active}
                      />
                    )}
                  </Menu.Item>
                )
            )}
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({active}) => (
                <button
                  className={`${
                    active ? 'bg-indigo-500 text-white' : 'text-red-600'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={logout}
                >
                  <span className="mr-2">
                    <Icon icon="tabler:logout" width={20} height={20} />
                  </span>
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileInitials;
