/** @jsxImportSource theme-ui */
import {Fragment, useEffect} from 'react';

import {Icon} from '@iconify/react';
import {UserPreferences} from '@parca/components';
import {
  Organization,
  Project,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {PolarSignalsFull} from '@polarsignals/icons';
import Link from 'next/link';
import router from 'next/router';
import useLocalStorageState from 'use-local-storage-state';

import useActiveProject from 'hooks/data/useActiveProject';
import useCurrentUser, {isAdminUser} from 'hooks/data/useCurrentUser';
import useProjects from 'hooks/data/useProjects';
import {hasNoProjects} from 'utils/projects';

import {ICONS} from '../../constants';
import ColorModeToggle from './ColorModeToggle';
import Dropdown from './Dropdown';
import NavbarActions from './NavbarActions';
import ProfileInitials from './ProfileInitials';

const isValidProject = (projectId: string, organizations: Organization[] | undefined = []) => {
  const project = organizations.find(org => org.projects.find(p => p.id === projectId));
  return !!project;
};

const findValidProject = (organizations: Organization[] = []) => {
  for (const org of organizations) {
    for (const project of org.projects) {
      if (project.id != null) {
        return project;
      }
    }
  }
};

const findOrganizationWithProject = (organizations: Organization[] = [], projectId: string) => {
  for (const org of organizations) {
    for (const project of org.projects) {
      if (project.id === projectId) {
        return org;
      }
    }
  }
};

const Navbar = () => {
  const projectIDFromUrl = router.query['project-id'];
  const [lastUsedProjectID, setLastUsedprojectID] = useLocalStorageState('lastUsedProjectId', {
    defaultValue: '',
  });

  const {
    data: {organizations},
  } = useProjects();

  const {
    data: {activeProject},
    mutations: {setActiveProject},
  } = useActiveProject();

  const {
    data: {user},
  } = useCurrentUser();

  const isAdmin = isAdminUser(user);

  const selectProject = (
    project: Project,
    organization: Organization,
    initiatedByEffect?: boolean
  ) => {
    setActiveProject(organization.id, project.id);
    setLastUsedprojectID(project.id);

    if (!initiatedByEffect) {
      router.push(`/projects/${project.id}`);
    }
  };

  useEffect(() => {
    if (projectIDFromUrl && isValidProject(projectIDFromUrl as string, organizations)) {
      setActiveProject(
        findOrganizationWithProject(organizations, projectIDFromUrl as string)?.id ?? '',
        projectIDFromUrl as string
      );
      return;
    }

    if (activeProject?.id != null && isValidProject(activeProject.id, organizations)) {
      return;
    }

    let projectIDToSelect: string = lastUsedProjectID;

    if (projectIDToSelect == null || !isValidProject(projectIDToSelect, organizations)) {
      projectIDToSelect = findValidProject(organizations)?.id ?? '';
    }

    if (!projectIDToSelect) {
      return;
    }

    const currentOrg = findOrganizationWithProject(organizations, projectIDToSelect);
    const projectToSelect = currentOrg?.projects.find(p => p.id === projectIDToSelect);

    if (!projectToSelect) {
      return;
    }

    selectProject(projectToSelect, currentOrg as Organization, true);
  }, [activeProject, organizations, selectProject, lastUsedProjectID]);

  return (
    <div
      sx={{borderBottom: '1px solid', borderColor: 'border'}}
      className="grid-cols-navbar grid items-center gap-4 p-4"
    >
      <div className="flex justify-center">
        <Link href="/">
          <a>
            <PolarSignalsFull width={200} />
          </a>
        </Link>
      </div>

      <div className="navbar-dropdown-container ml-4">
        {hasNoProjects(organizations) ? null : (
          <Dropdown text={activeProject?.name ?? 'Select Project'} element={<div>Project</div>}>
            {organizations?.map((organization, index) => (
              <Fragment key={index}>
                <Dropdown.ParentItem name={organization.name} orgId={organization.id} key={index} />
                {organization.projects.map((project, index) => (
                  <Dropdown.Item key={index} onSelect={() => selectProject(project, organization)}>
                    {project.name}
                  </Dropdown.Item>
                ))}
              </Fragment>
            ))}
          </Dropdown>
        )}
      </div>
      <div className="flex items-center justify-end gap-2">
        <ColorModeToggle />
        <UserPreferences />
        {isAdmin && (
          <Link href="/admin">
            <a className="p-1">
              <Icon icon={ICONS.admin} width={24} height={24} />
            </a>
          </Link>
        )}
        <NavbarActions />
        <ProfileInitials />
      </div>
    </div>
  );
};

export default Navbar;
